export const contentHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "title",
    label: "Title",
  },
  {
    id: "approval",
    label: "",
  },
  {
    id: "switch",
    label: "",
  },
  {
    id: "actions",
    label: "",
  },
  {
    id: "total_view",
    label: "Total View",
  },
  {
    id: "total_likes",
    label: "Total Likes",
  },
  {
    id: "notification_repeat",
    label: "Notification Repeat",
  },
  {
    id: "notification_interval",
    label: "Notification Interval",
  },
  {
    id: "notification_time",
    label: "Notification Time",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "pinned",
    label: "Pinned",
  },
  {
    id: "is_package_free",
    label: "Package Type",
  },
  {
    id: "author_name",
    label: "Author",
  },
  {
    id: "category_name",
    label: "Category",
  },
  {
    id: "sub_category_name",
    label: "SubCategory",
  },
];

export const contentsCategoriesHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "category_name",
    label: "Name",
  },
  {
    id: "product_category_color",
    label: "Icon",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const subCategoriesHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "product_category_color",
    label: "Icon",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const authorsHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "switch",
    label: "",
  },
  {
    id: "title",
    label: "Bio",
  },
  {
    id: "is_doctor",
    label: "Doctor",
  },
  {
    id: "amount_per_view_premium_content",
    label: "Amount Per View Premium Content",
  },
  {
    id: "amount_per_view_free_content",
    label: "Amount Per View Free Content",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const usersHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "userName",
    label: "Name",
  },
  {
    id: "profile_image",
    label: "Profile Image",
  },
  {
    id: "switch",
    label: "Active",
  },
  {
    id: "phoneNumber",
    label: "Phone number",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "age",
    label: "Age",
  },
  {
    id: "createdAt",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const contentSubscribersHeadCells = [
  {
    id: "msisdn",
    label: "MSISDN",
  },
  {
    id: "service_name",
    label: "Service Name",
  },
  {
    id: "active_date",
    label: "Active Date",
  },
  {
    id: "active_date",
    label: "Active Date",
  },
  {
    id: "expiry_date",
    label: "Expiry Date",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "added_at",
    label: "Created At",
  },
];

export const adsTrackingHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "ip_address",
    label: "IP Address",
  },
  {
    id: "tracking_id",
    label: "Tracking ID",
  },
  {
    id: "added_at",
    label: "Created At",
  },
];

export const doctorApplicationsHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "userName",
    label: "Name",
  },
  {
    id: "profile_image",
    label: "Profile Image",
  },
  {
    id: "switch",
    label: "Status",
  },
  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "phone_no",
    label: "Phone number",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "session_fee",
    label: "Session Fee",
  },
  {
    id: "has_attachment",
    label: "Has Attachment",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const specializationsHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "title",
    label: "Title",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "product_category_color",
    label: "Icon",
  },
  {
    id: "CREATED_AT",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const consultationHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "username",
    label: "Patient Name",
  },
  {
    id: "doctor_name",
    label: "Doctor Name",
  },
  {
    id: "session_id",
    label: "Session Id",
  },
  {
    id: "chat_id",
    label: "Chat Id",
  },
  {
    id: "ended_by",
    label: "Ended By",
  },
  {
    id: "availability",
    label: "Availability",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const chatSessionsAppealsHeadCells = [
  {
    id: "ID",
    label: "ID",
  },
  {
    id: "USER_NAME",
    label: "Patient Name",
  },
  {
    id: "DOCTOR_NAME",
    label: "Doctor Name",
  },
  {
    id: "APPEAL_BY",
    label: "Appealed By",
  },
  {
    id: "APPEAL_BODY",
    label: "Appeal Body",
  },
  {
    id: "SESSION_ID",
    label: "Session Id",
  },
  {
    id: "CHAT_ID",
    label: "Chat Id",
  },
  {
    id: "CREATED_AT",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const labInvestigationHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "userName",
    label: "Patient Name",
  },
  {
    id: "date_of_birth",
    label: "Date Of Birth",
  },
  {
    id: "user_gender",
    label: "Gender",
  },
  {
    id: "doctor_name",
    label: "Doctor Name",
  },
  {
    id: "medical_test_names",
    label: "Medical Test Names",
  },
  {
    id: "created_at",
    label: "Created At",
  },
];

export const medicinePrescriptionHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "userName",
    label: "Patient Name",
  },
  {
    id: "date_of_birth",
    label: "Date Of Birth",
  },
  {
    id: "user_gender",
    label: "Gender",
  },
  {
    id: "doctor_name",
    label: "Doctor Name",
  },
  {
    id: "medicine_prescription",
    label: "Medicine Prescription",
  },
  {
    id: "created_at",
    label: "Created At",
  },
];

export const medicalReportHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "userName",
    label: "Patient Name",
  },
  {
    id: "date_of_birth",
    label: "Date Of Birth",
  },
  {
    id: "user_gender",
    label: "Gender",
  },
  {
    id: "doctor_name",
    label: "Doctor Name",
  },
  {
    id: "chief_complaint",
    label: "Chief Complaint",
  },
  {
    id: "hpi",
    label: "HPI",
  },
  {
    id: "final_diagnosis",
    label: "Final Diagnosis",
  },
  {
    id: "reccomendation",
    label: "Reccomendation",
  },
  {
    id: "created_at",
    label: "Created At",
  },
];

export const diseasesHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "code",
    label: "Code",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const medicalTestsHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "code",
    label: "Code",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "category",
    label: "Category",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const medicinesHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "item",
    label: "Item",
  },
  {
    id: "unit",
    label: "Unit",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const medicalSchedulesHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const medicalRoutesHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const periodCycleHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "userName",
    label: "Name",
  },
  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "age",
    label: "Age",
  },
  {
    id: "last_period_start_date",
    label: "Last Period Start Date",
  },
  {
    id: "average_period_days",
    label: "Average period Days",
  },
  {
    id: "average_cycle_days",
    label: "Average Cycle Days",
  },
  {
    id: "created_at",
    label: "Created At",
  },
];

export const paymentsHeadCells = [
  {
    id: "order_id",
    label: "Order ID",
  },
  {
    id: "service_name",
    label: "Service Name",
  },
  {
    id: "package_type",
    label: "Package Type",
  },
  {
    id: "amount",
    label: "Amount",
  },
  {
    id: "payment_no",
    label: "Payment Number",
  },
  {
    id: "payment_status",
    label: "Status",
  },
  {
    id: "date",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const paymentsReportHeadCells = [
  {
    id: "date",
    label: "Date",
  },
  {
    id: "service_name",
    label: "Service Name",
  },
  {
    id: "total_users",
    label: "Total Users Paid",
  },
  {
    id: "total_paid",
    label: "Total Amount Paid",
  },
  {
    id: "total_users_joined_paid_today",
    label: "New Users Paid Today",
  },
  {
    id: "new_total_paid",
    label: "New Total Amount Paid",
  },
  {
    id: "total_users_renew",
    label: "Renewed Users",
  },
  {
    id: "renew_total_paid",
    label: "Total Amount Paid by Renewed Users",
  },
  {
    id: "total_users_joined_past_days_paid_today",
    label: "Past Users Paid Today",
  },
  {
    id: "total_revenue_for_past_users_joined_today",
    label: "Total Amount Paid by Past Users",
  },
];
export const UsersRenewHeadCells = [
  {
    id: "SN",
    label: "SN",
  },
  {
    id: "Name",
    label: "Name",
  },
  {
    id: "Date_package_expired",
    label: "Date package expired",
  },
  {
    id: "Date_package_renewed",
    label: "Date package Renewed",
  },
  {
    id: "Action",
    label: "Action",
  },
];

export const accountsExpireTomorrowHeadCells = [
  {
    id: "firstName",
    label: "First Name",
  },
  {
    id: "secondName",
    label: "Last Name",
  },
  {
    id: "phoneNumber",
    label: "Phone Number",
  },
  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "age",
    label: "Age",
  },
  {
    id: "payment_status",
    label: "Status",
  },
  {
    id: "createdAt",
    label: "Created At",
  },
];

export const packageCategoriesHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const subscriptionTypesHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "amount",
    label: "Amount",
  },
  {
    id: "active_days",
    label: "Active Days",
  },
  {
    id: "eng_package_description",
    label: "English  Package Description",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const ordersHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "order_id",
    label: "Order ID",
  },
  {
    id: "full_name",
    label: "Customer Name",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "phone_number",
    label: "Phone Number",
  },
  {
    id: "delivery_day",
    label: "Delivery Day",
  },
  {
    id: "delivery_time",
    label: "Delivery Time",
  },
  {
    id: "payment_method",
    label: "Payment Method",
  },
  {
    id: "order_status",
    label: "Order Status",
  },
  {
    id: "payment_status",
    label: "Payment Status",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const productsHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "product_name",
    label: "Product Name",
  },
  {
    id: "product_quantity",
    label: "",
  },
  {
    id: "switch",
    label: "",
  },
  {
    id: "actions",
    label: "",
  },
  {
    id: "product_amount",
    label: "selling Price",
  },
  {
    id: "product_promotion_amount",
    label: "Promotion Selling Price",
  },
  {
    id: "product_shipping_cost_in_dar",
    label: "Shipping Cost In Dar",
  },
  {
    id: "product_shipping_cost_in_other_regions",
    label: "Shipping Cost In Other Regions",
  },
  {
    id: "notification_repeat",
    label: "Notification Repeat",
  },
  {
    id: "notification_interval",
    label: "Notification Interval",
  },
  {
    id: "notification_time",
    label: "Notification Time",
  },
  {
    id: "created_at",
    label: "Created At",
  },
];

export const productCategoriesHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "product_category_name",
    label: "Category Name",
  },
  {
    id: "product_category_color",
    label: "Icon",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const productSubCategoriesHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "product_sub_category_name",
    label: "Sub Category Name",
  },
  {
    id: "icon",
    label: "Icon",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const productRatingsHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "user_name",
    label: "User Name",
  },
  {
    id: "value",
    label: "Value",
  },
  {
    id: "review",
    label: "Review",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const productSectionsHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "heading",
    label: "Heading",
  },
  {
    id: "product_sub_category_name",
    label: "Sub Category Name",
  },
  {
    id: "sort",
    label: "Sort",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const productBannersHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "product_name",
    label: "Product Name",
  },
  {
    id: "product_image",
    label: "Image",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const productCategoriesBannersHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "product_category_name",
    label: "Category Name",
  },
  {
    id: "product_image",
    label: "Image",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const regionsHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "region_name",
    label: "Region Name",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const districtsHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "district_name",
    label: "District Name",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const wardsHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "ward_name",
    label: "Ward Name",
  },
  {
    id: "shipping_cost",
    label: "Shipping Cost",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const servicesProvidedHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "service_name",
    label: "Service Name",
  },
  {
    id: "service_type",
    label: "Service Type",
  },
  {
    id: "service_color",
    label: "Icon",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const productOrderStatusHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Order Name",
  },
  {
    id: "description",
    label: "Order Description",
  },
  {
    id: "icon",
    label: "Order Image",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const tribesHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "tribe_name",
    label: "Tribe Name",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const interestsHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "interest_name",
    label: "Interest Name",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const datingUsersHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "full_name",
    label: "Name",
  },
  {
    id: "nick_name",
    label: "Nick Name",
  },
  {
    id: "actions",
    label: "",
  },
  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "age",
    label: "Age",
  },
  {
    id: "tribe_name",
    label: "Tribe Name",
  },
  {
    id: "education_level",
    label: "Education Level",
  },
  {
    id: "relationship_goal",
    label: "Relationship Goal",
  },
  {
    id: "distance_preference",
    label: "Distance Preference",
  },
  {
    id: "interests",
    label: "Interests",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "created_at",
    label: "Created At",
  },
];

export const datingUsersChatsHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "male_name",
    label: "Male Name",
  },
  {
    id: "female_name",
    label: "Female Name",
  },
  {
    id: "session_id",
    label: "Session ID",
  },
  {
    id: "chat_id",
    label: "Chat ID",
  },
  {
    id: "availability",
    label: "Availability",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "",
  },
];

export const campaignHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "title",
    label: "Title",
  },
  {
    id: "image",
    label: "Image",
  },
  {
    id: "campaign_description",
    label: "Description",
  },
  {
    id: "start_time",
    label: "Start Time",
  },
  {
    id: "end_time",
    label: "End Time",
  },
  {
    id: "added_at",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const campaignPackageHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "amount",
    label: "Amount",
  },
  {
    id: "active_days",
    label: "Active Days",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "added_at",
    label: "End Time",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export const campaignParticipantsHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "userName",
    label: "Name",
  },
  {
    id: "profile_image",
    label: "Profile Image",
  },
  {
    id: "phoneNumber",
    label: "Phone number",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "age",
    label: "Age",
  },
  {
    id: "purchase_count",
    label: "Purchase Count",
  },
  {
    id: "total_amount",
    label: "Total Amount",
  },
];
